<template>
  <div class="colo-prolong-multi">
    <base-prolong
      :title="$t('title')"
      :list="list"
      show-total
      @ready="onReady"
      @change="onChange"
      @change-total="onChangeTotal"
    />
  </div>
</template>

<script>
import BaseProlong from '@/components/BaseProlong/BaseProlong';
export default {
  name: 'ColoProlongMulti',
  components: { BaseProlong },
  props: {
    dataset: {
      type: Object,
      required: true,
      validator: obj => Object.values(obj).every(i => i.tariff && i.list && i.period && i.value),
    },
  },
  computed: {
    listIds() {
      return Object.keys(this.dataset)
        .map(i => `#${i}`)
        .join(', ');
    },
    list() {
      return Object.values(this.dataset).map(item => {
        let subtitle = `#${item.tariff.id}`;
        if (item.tariff.domain) subtitle += `, ${item.tariff.domain}`;
        return {
          title: `${this.$t('titleTariff')} ${item.tariff.pricelist}`,
          subtitle,
          list: item.list,
          value: item.value,
          id: item.tariff.id,
        };
      });
    },
  },
  methods: {
    onReady() {
      this.$emit('ready');
    },
    onChange(value) {
      this.$emit('change', value);
    },
    onChangeTotal(value) {
      this.$emit('change-total', value);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Продление услуг",
    "titleTariff": "Продление тарифа"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.colo-prolong-multi {}
</style>
